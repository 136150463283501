import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 480px;
`;

const Content = () => {

  const data = useStaticQuery(graphql`
    query {
      good_vibes_only: file(relativePath: { eq: "good_vibes_only.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cactus: file(relativePath: { eq: "cactus.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Section>
        <Container>
          <Row className="justify-content-center">
            <Col>
              <Title variant="quote" className="mb-3">
                We know Maximo.
              </Title>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col>
              <Text>
                For more than 15 years we've been implementing Maximo and mobility for Maximo. 
                Along the way we’ve tackled many unique and interesting challenges. 
              </Text>
              <Text mt={3}>
                We've implemented and upgraded Maximo for clients both small and large, across a wide range of industries. 
                We’ve developed mobile solutions for asset management, work management, safety and condition inspections, 
                and inventory management.
              </Text>
              <Text mt={3}>
                We've written Maximo tools, utilities, integrations and even an entire mobile platform that runs entirely within Maximo. 
              </Text>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col>
              <Title variant="quote" className="mb-3">
                We know our customers.
              </Title>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col>
              <Text>
                We believe that it is only possible to help a customer if you understand, are
                interested in and deeply care about what they are trying to achieve.
              </Text>
              <Text mt={3}>
                We treat our customers the way we would want to be treated, with honesty, openness and transparency.
              </Text>
              <Text mt={3}>
                As people, helping others to succeed and building relationships is what motivates us.
              </Text>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col>
              <Title variant="quote" className="mb-3">
                We think it should be simpler.
              </Title>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col>
              <Text>
                Simple isn’t simplistic, in fact identifying and describing what’s important,
                what matters to a business process, can be extremely challenging.
              </Text>
              <Text mt={3}>
                Maximo is not your business, but it is ours.  We are using our years of experience
                to identify and address the challenges of implementing complex processes in Maximo in
                a way that makes them simple, approachable and even enjoyable.
              </Text>
              <Text mt={3}>
                All this is so Maximo can support growing and evolving your business and not be an obstacle to it.
                Not just directly for your Maximo users, but also for your field workers, managers, and administrators.
              </Text>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg="4" sm="12" className="mb-4">
              <ContentImg>
                <Img fluid={data.good_vibes_only.childImageSharp.fluid} />
              </ContentImg>
            </Col>
            <Col lg="8" sm="12" className="mb-4">
              <ContentImg>
                <Img fluid={data.cactus.childImageSharp.fluid} />
              </ContentImg>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Content;
