import React from "react";
import PageHero from "../components/PageHero";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import Content from "../sections/about/Content";
import Team from "../sections/about/Team";

const About = (props) => {
  return (
    <>
      <PageWrapper>
      <Seo 
          title="Sharptree | About | Mobile Reimagined"
          description="We know Maximo, we know our customers, and we think it should be simpler. With our years of experience, we know how to get the most out of Maximo."
          pathname={props.location.pathname} 
        />
        <PageHero title="About Us" />
        <Content />
        <Team />
      </PageWrapper>
    </>
  );
};
export default About;
